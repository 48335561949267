const complex = {
    state: () => ({
        complexName: ''
    }),
    mutations: {
        setComplexName(state, name) {
            console.log(name,'name')
            state.complexName = name
        }
    }
}

export default  complex;
