import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/user/Home.vue'
import store from "../store/store";
import Headers from "../services/headers";
import Login from "../services/login";
import User from "../services/user";
import Notification from "@/services/notification";

const routes = [
  { path: '/userProperty', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') },
  { path: '/register', name: 'Register', component: () => import(/* webpackChunkName: "register" */ '../views/login/Login.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/loginPetition/:id', name: 'loginPetition', component: () => import(/* webpackChunkName: "register" */ '../views/login/Login.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/loginSurvey/:id', name: 'loginSurvey', component: () => import(/* webpackChunkName: "register" */ '../views/login/Login.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/registerFill', name: 'RegisterFill', component: () => import(/* webpackChunkName: "register" */ '../views/login/Register.vue'),
    meta: {noAuth: true, ignore: true}, beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/login', name: 'Login', component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'), meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/changeNumber', name: 'ChangeNumber', component: () => import(/* webpackChunkName: "changeNumber" */ '../views/login/Login.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/mobileIdWaiting', name: 'MobileIdWaiting', component: () => import(/* webpackChunkName: "mobileIdWaiting" */ '../views/login/MobileIdWaiting.vue'),
    meta: {noAuth: true}, beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/statusInformation', name: 'StatusInformation', component: () => import(/* webpackChunkName: "statusInformation" */ '../views/login/StatusInformation.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true}, beforeEnter: () => {return store.getters.isRequestId}},
  { path: '/smsCheck', name: 'SmsCheck', component: () => import(/* webpackChunkName: "smsCheck" */ '../views/login/SmsCheck.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true}, beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/phoneVerification', name: 'PhoneVerification', component: () => import(/* webpackChunkName: "phoneVerification" */ '../views/login/PhoneVerification.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true}, beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/ChangePassword', name: 'ChangePassword', component: () => import(/* webpackChunkName: "changePasswordFrm" */ '../views/login/ChangePassword.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true} },
  { path: '/errorPage', name: 'ErrorPage', component: () => import(/* webpackChunkName: "errorPage" */ '../views/ErrorPage.vue'),  meta: {noAuth: true} },
  { path: '/needHelp', name: 'NeedHelp', component: () => import(/* webpackChunkName: "needHelp" */ '../views/login/NeedHelp.vue'),
    meta: {noAuth: true, ignore: true, hideMenu: true},  beforeEnter: () => {return store.getters.isRequestId}  },
  { path: '/house/:id', name: 'House', component: () => import(/* webpackChunkName: "house" */ '../views/house/House.vue') },
  { path: '/houses', name: 'Houses', component: () => import(/* webpackChunkName: "houses" */ '../views/house/Houses.vue'), meta: {role: 'housing', isSwitch: true} },
  { path: '/gatherings', name: 'Gatherings', component: () => import(/* webpackChunkName: "gatherings" */ '../views/gathering/Gatherings.vue'), meta: {role: 'gathering'} },
  { path: '/undraising', name: 'Goals', component: () => import(/* webpackChunkName: "goals" */ '../views/gathering/Goals.vue') },
  { path: '/success/:id', name: 'Success', component: () => import(/* webpackChunkName: "success" */ '../views/gathering/Success.vue') },
  { path: '/gatheringDetails/:id', name: 'GatheringDetails', component: () => import(/* webpackChunkName: "gatheringDetails" */ '../views/gathering/GatheringDetails.vue'),
    meta: {parent: 'Gatherings'}  },
  { path: '/goalDetails/:id', name: 'GoalDetails', component: () => import(/* webpackChunkName: "goalDetails" */ '../views/gathering/GatheringDetails.vue'), meta: {parent: 'Goals'} },
  { path: '/userProfile', name: 'UserProfile', component: () => import(/* webpackChunkName: "userPayments" */ '../views/user/UserProfile.vue') },
  { path: '/userMenu',
    name: 'UserMenu',
    component: () => import(/* webpackChunkName: "userMenu" */ '../views/user/UserMenu.vue'),
    children: [
      {
        path: 'userProperty',
        name: 'Home',
        component: Home,
      },
      {
        path: 'userPayments',
        name: 'UserPayments',
        component: () => import(/* webpackChunkName: "userPayments" */ '../views/user/UserPayments.vue'),
        meta: {isSwitch: true}
      },
      {
        path: 'userSignatures',
        name: 'UserSignatures',
        component: () => import(/* webpackChunkName: "userSignatures" */ '../views/user/UserSignatures.vue'),
      },
    ]

  },
  { path: '/viewUserMenu/:id',
    name: 'viewUserMenu',
    component: () => import(/* webpackChunkName: "viewUserMenu" */ '../views/user/UserMenu.vue'),
    meta: {role: 'Editing'} ,
    children: [
      {
        path: 'viewUserProperty',
        name: 'viewHome',
        component: Home,
        meta: {role: 'Editing'} ,
      },
      {
        path: 'viewUserPayments',
        name: 'viewUserPayments',
        meta: {role: 'Editing'} ,
        component: () => import(/* webpackChunkName: "viewUserPayments" */ '../views/user/UserPayments.vue')
      },
    ]
  },
  // { path: '/viewUserProfile', name: 'viewUserProfile', meta: {role: 'Editing'}, component: () => import(/* webpackChunkName: "viewUserProfile" */ '../components/user/UserProfile.vue')},
  { path: '/404', name: 'PageNotFound', component: () => import(/* webpackChunkName: "payments" */ '../views/PageNotFound.vue') },
  { path: '/payments', name: 'Payments', component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue'), meta: {role: 'gathering', isSwitch: true} },
  { path: '/browsingUsers', name: 'BrowsingUsers', component: () => import(/* webpackChunkName: "browsingUsers" */ '../views/BrowsingUsers.vue'), meta: {role: 'gathering', isSwitch: true} },
  { path: '/gatheringConstructor', name: 'GatheringConstructor',
    component: () => import(/* webpackChunkName: "gatheringConstructor" */ '../views/gathering/GatheringConstructor.vue'),
    meta: {parent: 'Gatherings'} },
  { path: '/gatheringEditing/:id', name: 'GatheringEditing',
    component: () => import(/* webpackChunkName: "gatheringEditing" */ '../views/gathering/GatheringConstructor.vue'),
    meta: {parent: 'Gatherings', role: 'Editing'} },
  { path: '/direct/:id', name: 'FastPayment', component: () => import(/* webpackChunkName: "direct" */ '../views/gathering/FastPayment.vue'), meta: {noAuth: true, hideMenu: true} },
  { path: '/directSuccess/:id', name: 'directSuccess', component: () => import(/* webpackChunkName: "directSuccess" */ '../views/gathering/FastPaymentSuccess.vue'), meta: {noAuth: true, hideMenu: true} },
  { path: '/petitionConstructor', name: 'PetitionConstructor',
    component: () => import(/* webpackChunkName: "petitionConstructor" */ '../views/petition/PetitionConstructor.vue'),
    meta: {parent: 'PetitionViewingAdmin'}},
  { path: '/petitionEditing/:id', name: 'PetitionEditing',
    component: () => import(/* webpackChunkName: "petitionEditing" */ '../views/petition/PetitionConstructor.vue'),
    meta: {parent: 'PetitionViewingAdmin', role: 'Editing'}},
  { path: '/petitionViewingAdmin', name: 'PetitionViewingAdmin', component: () => import(/* webpackChunkName: "petitionViewingAdmin" */ '../views/petition/PetitionViewing'),
    meta: {role: 'superAdmin', statusName: 'petition'} },
  { path: '/petitionViewing', name: 'PetitionViewing', component: () => import(/* webpackChunkName: "petitionViewing" */ '../views/petition/PetitionViewing'),
    meta: {statusName: 'petition'}},
  {
    path: '/petitionDetailsAdmin/:id', name: 'PetitionDetailsAdmin', component: () => import(/* webpackChunkName: "petitionDetailsAdmin" */ '../views/petition/PetitionDetailsAdmin.vue'),
    meta: { parent: 'PetitionViewingAdmin', isSwitch: true, role: 'superAdmin', statusName: 'petition'}
  },
  { path: '/petitionDetails/:id', name: 'PetitionDetails', component: () => import(/* webpackChunkName: "petitionDetails" */ '../views/petition/PetitionDetailsAdmin.vue'),
    meta: { parent: 'PetitionViewing',  statusName: 'petition' } },
  { path: '/petitionDirect/:id', name: 'PetitionDirect', component: () => import(/* webpackChunkName: "petitionDirect" */ '../views/petition/PetitionDirect.vue'), meta: { noAuth: true, hideMenu: true } },
  { path: '/petitionDirectSign/:id', name: 'PetitionDirectSign', component: () => import(/* webpackChunkName: "petitionDirectSign" */ '../views/login/Register.vue'),
    meta: { noAuth: true, ignore: true },beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/mobileIdWaiting', name: 'MobileIdWaiting', component: () => import(/* webpackChunkName: "mobileIdWaiting" */ '../views/login/MobileIdWaiting.vue'),
    meta: { noAuth: true, ignore: true, hideMenu: true }  },
  { path: '/petitionDirectSuccess', name: 'PetitionDirectSuccess', component: () => import(/* webpackChunkName: "petitionDirectSuccess" */ '../views/petition/PetitionDirectSuccess.vue'),
    meta: { noAuth: true, ignore: true, hideMenu: true } },
  { path: '/petitionDirectValidation/:id', name: 'PetitionDirectValidation',
    component: () => import(/* webpackChunkName: "petitionDirectValidation" */ '../views/login/Register.vue'),
    meta: { noAuth: true, hideMenu: true } },
  {
    path: '/ticketConstructor', name: 'TicketConstructor',
    component: () => import(/* webpackChunkName: "ticketConstructor" */ '../views/ticket/TicketConstructor.vue'),
  },
  {
    path: '/ticketEditor', name: 'TicketEditor',
    component: () => import(/* webpackChunkName: "ticketEditor" */ '../views/ticket/TicketConstructor.vue'), meta: {function: 'Editing'},
  },
  { path: '/ticketDetails/:id', name: 'TicketDetails', component: () => import(/* webpackChunkName: "ticketDetails" */ '../views/ticket/TicketDetails.vue'),
    meta: { parent: 'TicketViewing',  statusName: 'ticket'}},
  { path: '/ticketDetailsAdmin/:id', name: 'TicketDetailsAdmin', component: () => import(/* webpackChunkName: "ticketDetailsAdmin" */ '../views/ticket/TicketDetails.vue'),
    meta: { parent: 'TicketViewingAdmin', statusName: 'ticket', role: 'superAdmin'}},
  { path: '/ticketViewing', name: 'TicketViewing', component: () => import(/* webpackChunkName: "ticketViewing" */ '../views/ticket/TicketViewing.vue'),
    meta: { parent: 'TicketViewing'}},
  { path: '/ticketViewingAdmin', name: 'TicketViewingAdmin',
    component: () => import(/* webpackChunkName: "ticketViewingAdmin" */ '../views/ticket/TicketViewing.vue'), meta: {role: 'superAdmin', parent: 'TicketViewingAdmin'}},
  { path: '/surveyConstructor', name: 'SurveyConstructor',
    component: () => import(/* webpackChunkName: "surveyConstructor" */ '../views/survey/SurveyConstructor.vue'), meta: { parent: 'SurveyViewingAdmin'}
  },
  { path: '/surveyEditing/:id', name: 'SurveyEditing',
    component: () => import(/* webpackChunkName: "surveyEditing" */ '../views/survey/SurveyConstructor.vue'), meta: {parent: 'SurveyViewingAdmin', role: 'Editing'}
  },
  { path: '/surveyViewing', name: 'SurveyViewing', component: () => import(/* webpackChunkName: "surveyViewing" */ '../views/survey/SurveyViewing.vue'),
    meta: { parent: 'SurveyViewing'}},
  { path: '/surveyViewingAdmin', name: 'SurveyViewingAdmin', component: () => import(/* webpackChunkName: "surveyViewingAdmin" */ '../views/survey/SurveyViewing.vue'),
    meta: {role: 'superAdmin', statusName: 'survey'} },
  { path: '/userSurvey/:id', name: 'UserSurvey', component: () => import(/* webpackChunkName: "userSurvey" */ '../views/survey/UserSurvey.vue'),
    meta: { parent: 'SurveyViewing',  statusName: 'survey' }, beforeEnter: () => {return store.getters.isUserInformationComplete} },
  { path: '/userSurveyEditing/:id', name: 'UserSurveyEditing', component: () => import(/* webpackChunkName: "userSurveyEditing" */ '../views/survey/UserSurvey.vue'),
    meta: { parent: 'SurveyViewing',  statusName: 'survey', function: 'Editing' }, beforeEnter: () => {return store.getters.isUserInformationComplete} },
  { path: '/surveyDetailsAdmin/:id', name: 'SurveyDetailsAdmin', component: () => import(/* webpackChunkName: "surveyDetailsAdmin" */ '../views/survey/SurveyDetails.vue'),
    meta: { parent: 'SurveyViewingAdmin', role: 'superAdmin', statusName: 'survey' , isSwitch: true} },
  { path: '/surveyDetails/:id', name: 'SurveyDetails', component: () => import(/* webpackChunkName: "userSurvey" */ '../views/survey/SurveyDetails.vue'),
    meta: { parent: 'SurveyViewing',  statusName: 'survey' } },
  { path: '/surveyResultDetails/:id', name: 'SurveyResultDetails', component: () => import(/* webpackChunkName: "surveyResultDetails" */ '../views/survey/SurveyResultDetails.vue'),
    meta: { parent: 'SurveyViewing', statusName: 'survey' } },
  { path: '/surveyResultDetailsAdmin/:id', name: 'SurveyResultDetailsAdmin', component: () => import(/* webpackChunkName: "surveyResultDetails" */ '../views/survey/SurveyResultDetails.vue'),
    meta: { parent: 'SurveyViewingAdmin',  role: 'superAdmin', statusName: 'survey',  isSwitch: true} },
  { path: '/requestsHouse/:id', name: 'RequestsHouse', component: () => import(/* webpackChunkName: "requestsHouse" */ '../components/houses/RequestsHouse'),
    meta: {  role: 'superAdmin'} },
  { path: '/surveyDirect/:id', name: 'surveyDirect', component: () => import(/* webpackChunkName: "surveyDirect" */ '../views/survey/SurveyDirect'), meta: {noAuth: true, hideMenu: true} },
  { path: '/surveyDirectSign/:id', name: 'SurveyDirectSign', component: () => import(/* webpackChunkName: "petitionDirectSign" */ '../views/login/Register.vue'),
    meta: { noAuth: true, ignore: true },beforeEnter: () => {return store.getters.isRequestId} },
  { path: '/surveyDirectVoting/:id', name: 'SurveyDirectVoting', component: () => import(/* webpackChunkName: "userSurvey" */ '../views/survey/UserSurvey.vue'),
    meta: { hideMenu: true}, beforeEnter: () => {return store.getters.isUserInformationComplete} },
  { path: '/SurveyDirectSuccess', name: 'SurveyDirectSuccess', component: () => import(/* webpackChunkName: "userSurvey" */ '../views/survey/SurveyDirectSuccess.vue'),
    meta: { hideMenu: true} },
  { path: '/surveyViewUserResult/:id', name: 'SurveyViewUserResult', component: () => import(/* webpackChunkName: "surveyViewUserResult" */ '../views/survey/SurveyDetails.vue'),
    meta: {  statusName: 'survey' } },
  { path: '/requestHouse/:id', name: 'RequestHouse',
    component: () => import(/* webpackChunkName: "requestHouse" */ '../views/house/RequestHouse.vue'), meta: {role: 'superAdmin', parent: 'Houses'}},
  { path: '/', name: 'AddUserHome', component: () => import(/* webpackChunkName: "addUserHome" */ '../views/AddUserHome.vue'), meta: { hideMenu: true, noAuth: true} },
  { path: '/:catchAll(.*)', redirect: '404' }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if ( !from.name || (from.meta && from.meta.ignore)) {
    if (from.meta) {
       from.meta.hideMenu = true;
    }
    to.redirectedFrom = from.redirectedFrom;
  } else {
    to.redirectedFrom = from;
  }
  if (store.state.user.isAuthenticated === undefined) {
    document.cookie = "django_language = ru"
    await Headers.getCsrfToken().then(() => {
      return Login.getUserDetails();
    }).then((data) => {
      store.dispatch('checkAuthenticated', data.status === "OK" && data.data.phone_verified);
      store.commit('setRoles', data.data?.Roles);
      store.commit('setFullUserInformation', data.data)
      store.commit('setNumberPhone', data.data?.phone)
      if (store.state.user.isAuthenticated) {
        return User.getUserApartment();
      }
    })
  }
  window.scrollTo(0, 0);
  if ((to.name === 'Login' || to.name === 'Register' ||  to.name === 'ChangePassword' || to.name === 'RegisterFill')
      && store.state.user.isAuthenticated) {
    next({name: 'Home'});
  }
  else if (to.meta.noAuth){
    if (to.name === 'RegisterFill' && !store.state.request.requestId) {
      next({name: 'Register'});
    } else if (to.name === 'PetitionDirectSign' && !store.state.request.requestId) {
      next({name: 'loginPetition', params: {id: to.params.id}});
    } else if (to.name === 'loginPetition' && store.state.user.isAuthenticated) {
      next({name: 'PetitionDirect', params: {id: to.params.id}});
    } else {
      next();
    }
  } else if (!store.state.user.isAuthenticated) {
    next({name: 'Login'});
    from.redirectedFrom = to;
  } else if (!to.meta.role || store.state.user.roles.includes(to.meta.role) || store.state.user.roles.includes("superadmin")
  ||  store.state.user.roles.includes("admin")) {
    await Notification.getNotifications();
    next();
  } else {
    next({name: 'Home'});
  }
})

export default router
