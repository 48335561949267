import axios from "axios";
import API from "./api";
import store from "../store/store";
import router from "@/router";
import Headers from "./headers";

export default class Error {
    static addError(error) {
        if (error.code === "ERR_CANCELED") return {status: 'canceled'};
        switch (error.response.request.status) {
            case 403: {
                store.dispatch('checkAuthenticated', false);
                router.push({name: 'Login'});
                break;
            }
            case 401: {
               Headers.getCsrfToken().then();
               break
            }
            default: {
                store.commit('addError', error)
            }
        }
        throw error;
    }

    static addLogError(params) {
        return axios.post(`${API.API}/common/incident_front_set`, params).then(
            (response) => {
                return response.data.data;
            }
        ).catch( () => []).then((data) => {
            return data
        })
    }
}
