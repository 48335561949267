import { createStore} from "vuex";
import user from "./user";
import house from "./house";
import error from "./error";
import request from "./request"
import petitions from "./petitions";
import editingGathering from "./editingGathering";
import file from "./file";
import device from "./device";
import savingFilters from "@/store/savingFilters";
import status from "@/store/status";
import notification from "@/store/notifications";
import complex from "./complex";


export default createStore({
    modules: {
        user: user,
        house: house,
        error: error,
        request: request,
        petitions: petitions,
        editingGathering: editingGathering,
        file,
        device,
        savingFilters,
        status,
        notification, 
        complex
    }
});

