const house = {
    state: () => ({
        name: '',
        address: '',
        complexName: ''
    }),
    mutations: {
        changeHouse(state, house) {
            state.name = house.name;
            state.address = house.address;
        }, 
        setComplexName(name) {
            this.state.complexName = name
        }
    }
}

export default  house
